export function getAuthenticatedUserId(): string | undefined {
  const identityServerBaseUrl = window.ICE.settings.identityServerBaseUrl;
  const sessionStorageKey = `oidc.user:${identityServerBaseUrl}oauth2:OrderformWeb`;
  const storage = sessionStorage.getItem(sessionStorageKey);

  if (storage) {
    const parsedStorage = JSON.parse(storage);
    const userId = parsedStorage.profile.sub;
    return userId;
  }

  return undefined;
}
