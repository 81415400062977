export function detectBrowser(userAgent: string) {
  // The order is significant, do not rearrange.
  if (userAgent.includes("Firefox")) {
    const version = detectBrowserVersion(userAgent, /Firefox\/(\S+)/);
    return { name: "Mozilla Firefox", version };
  } else if (userAgent.includes("SamsungBrowser")) {
    const version = detectBrowserVersion(userAgent, /SamsungBrowser\/(\S+)/);
    return { name: "Samsung Internet", version };
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    const version = detectBrowserVersion(userAgent, /OPR\/(\S+)/);
    return { name: "Opera", version };
  } else if (userAgent.includes("Edge")) {
    const version = detectBrowserVersion(userAgent, /Edge\/(\S+)/);
    return { name: "Microsoft Edge (Legacy)", version };
  } else if (userAgent.includes("Edg")) {
    const version = detectBrowserVersion(userAgent, /Edg\/(\S+)/);
    return { name: "Microsoft Edge (Chromium)", version };
  } else if (userAgent.includes("Chrome")) {
    const version = detectBrowserVersion(userAgent, /Chrome\/(\S+)/);
    return { name: "Google Chrome", version };
  } else if (userAgent.includes("Safari")) {
    const version = detectBrowserVersion(userAgent, /Version\/(\S+)/);
    return { name: "Apple Safari", version };
  }
  return { name: "unknown", version: "unknown" };
}

function detectBrowserVersion(userAgent: string, regexp: RegExp) {
  const versionMatch = userAgent.match(regexp);
  return versionMatch ? versionMatch[1] : "unknown";
}
